import React from 'react'

import mappic from '../images/mappic.jpg'
import macRates from '../images/mac_rates.png'


export default class Location extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="locationpage">
          <h2 className="major">Travel / Accommodations</h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <iframe
              title="wedding venue"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2905.3997618288404!2d-79.92182898409602!3d43.264000179136545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c84acae707f6d%3A0x7ce5da8d58cea718!2sThe+University+Club+of+McMaster!5e0!3m2!1sen!2sca!4v1560737164338!5m2!1sen!2sca"
              className="main"
              height="300px"
              width="100%"
            ></iframe>
          </div>
          <hr></hr>
          <p>The full venue address is:</p>
          <p>
            <b> Alumni Memorial Hall, 1280 Main St W, Hamilton, ON L8S 4K1</b>
          </p>

          <p>
            Click the above map to be redirected to google maps. From there you
            should be able to get directions. Please read about parking below.
          </p>
          <h4 className="major">Accommodations</h4>
          <p>
            If you'd like to sleep in a residence building 5 mins from the venue
            <a href="https://hotel.mcmaster.ca/affordable/">
              , here's a link to booking a room on campus
            </a>
            .
          </p>
          <ul>
            <li>
              <a href="https://hotel.mcmaster.ca/affordable/">
                McMaster (click here to book)
              </a>
              <ul>
                <li>Les Prince - Single ($87)</li>
                <li>Les Prince - Double ($139)</li>
                <li>PGCLL - Double ($163)</li>
              </ul>
            </li>
            <li>
              <a href="https://www.hilton.com/en/hotels/yhmhwhw-homewood-suites-hamilton-ontario-canada/">
                Hilton (~$200 / night) (click here to book)
              </a>
            </li>
          </ul>
          <h4 className="major">Parking</h4>
          <p>
            Parking is included (please let us know in the RSVP) and is located 450m from the university club.
            Folks that need to be dropped off closer can if they just follow
            <i> Stearn Drive</i> around Ron Joyce Stadium. It's about 150m from
            there.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <iframe
              title="wedding parking"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1221.5841819983275!2d-79.91604987310738!3d43.26329950439266!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9b539fb38b91%3A0x2ece9fbb0a30c4fc!2sMcMaster+Parking+Lot+Zone+B%2C+Hamilton%2C+ON+L8S+4E8!5e0!3m2!1sen!2sca!4v1562701885594!5m2!1sen!2sca"
              className="main"
              height="300px"
              width="100%"
            ></iframe>
          </div>
          <p></p>

          <h4 className="major">Walking to the building</h4>

          <span>
            <img src={mappic} width="100%" alt="How to Walk There." />
          </span>


        </div>
      </React.Fragment>
    )
  }
}
