import React, { Component } from 'react'

import carlife from '../images/carlife.jpg'

export default class Gifts extends Component {
  render() {
    return (
      <React.Fragment>
        <h2 className="major">Gifts</h2>
        <span className="image main">
          <img src={carlife} alt="Picture of a full car" />
        </span>

        <p>
          We're asking for funds to help pay for the wedding, a matching bedroom set and saving for a bigger vehicle!
        </p>
      </React.Fragment>
    )
  }
}
