import React from 'react'

const guestCodes = ['3LI48', 'P@AMG', 'I!Y@C', '0V6GD']

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class RSVP extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guests: 1,
      formControls: {
        email1: {
          value: '',
        },
        name1: {
          value: '',
        },
        attending1: {
          value: 'yes',
        },
        meal1: {
          value: '',
        },
        restrictions1: {
          value: '',
        },
        bday1: {
          value: '',
        },
        parking: {
          value: 'yes',
        },
        email2: {
          value: '',
        },
        name2: {
          value: '',
        },
        attending2: {
          value: 'yes',
        },
        meal2: {
          value: '',
        },
        restrictions2: {
          value: '',
        },
        bday2: {
          value: '',
        },
        email3: {
          value: '',
        },
        name3: {
          value: '',
        },
        attending3: {
          value: 'yes',
        },
        meal3: {
          value: '',
        },
        restrictions3: {
          value: '',
        },
        bday3: {
          value: '',
        },
        email0: {
          value: '',
        },
        name0: {
          value: '',
        },
        attending0: {
          value: 'yes',
        },
        meal0: {
          value: '',
        },
        restrictions0: {
          value: '',
        },
        bday0: {
          value: '',
        },
      },
    }
  }

  guestInfo(i, showFullForm = false) {
    return (
      <div key={i}>
        <hr></hr>
        <h3> {i != 0 ? `Plus One` : ''}</h3>
        <div className="field half first">
          <div className="label">Name</div>
          <input
            type="text"
            name={`name${i}`}
            id={`name${i}`}
            value={this.state.formControls[`name${i}`].value}
            onChange={this.changeHandler}
            required
          />
        </div>
        <div className="field half">
          <div className="label">Email</div>
          <input
            type="email"
            name={`email${i}`}
            id={`email${i}`}
            value={this.state.formControls[`email${i}`].value}
            onChange={this.changeHandler}
            required
          />
        </div>
        <div className="label">Are You attending?</div>
        <div className="field" id={`attending${i}`}>
          <input
            type="radio"
            name={`attending${i}`}
            value="yes"
            id={`yes${i}`}
            checked={this.state.formControls[`attending${i}`].value === 'yes'}
            onChange={this.changeHandler}
            required
          />
          <label htmlFor={`yes${i}`}>
            <span>Yes</span>
          </label>
          <input
            type="radio"
            name={`attending${i}`}
            value="no"
            id={`no${i}`}
            checked={this.state.formControls[`attending${i}`].value === 'no'}
            onChange={this.changeHandler}
            required
          />
          <label htmlFor={`no${i}`}>
            <span>No</span>
          </label>
        </div>
        {this.state.formControls[`attending${i}`].value === 'yes' ||
          showFullForm ? (
          <div>
            <label htmlFor={`meal${i}`}>Please Select Your Meal</label>
            <div className="field">
              <input
                type="radio"
                name={`meal${i}`}
                value="meat"
                id={`opt1${i}`}
                onChange={this.changeHandler}
                checked={this.state.formControls[`meal${i}`].value === 'meat'}
                required
              />
              <label htmlFor={`opt1${i}`}>
                <span>Beef Striploin</span>
              </label>
              <input
                type="radio"
                name={`meal${i}`}
                value="fish"
                id={`opt2${i}`}
                onChange={this.changeHandler}
                checked={
                  this.state.formControls[`meal${i}`].value === 'fish'
                }
                required
              />
              <label htmlFor={`opt2${i}`}>
                <span>Baked Arctic Char</span>
              </label>
              <input
                type="radio"
                name={`meal${i}`}
                value="vegan"
                id={`opt3${i}`}
                onChange={this.changeHandler}
                checked={this.state.formControls[`meal${i}`].value === 'vegan'}
                required
              />
              <label htmlFor={`opt3${i}`}>
                <span>Vegan</span>
              </label>
            </div>
            <div className="field">
              <span className="label">
                Please note any dietary restrictions
              </span>
              <textarea
                name={`restrictions${i}`}
                id={`restrictions${i}`}
                rows="1"
                value={this.state.formControls[`restrictions${i}`].value}
                onChange={this.changeHandler}
              ></textarea>
            </div>
            <div className="field">
              <span className="label">
                Help us do better with birthdays, when is yours (month and day only is fine!)
              </span>
              <input
                type="text"
                name={`bday${i}`}
                id={`bday${i}`}
                value={this.state.formControls[`bday${i}`].value}
                onChange={this.changeHandler}
              />
            </div>

          </div>
        ) : null}
      </div>
    )
  }

  parkingField = () => <><div className="label">Do you need a parking pass? (for the group)</div>
    <div className="field" id={`parking`}>
      <input
        type="radio"
        name={`parking`}
        value="yes"
        id={`yes`}
        checked={this.state.formControls[`parking`].value === 'yes'}
        onChange={this.changeHandler}
        required
      />
      <label htmlFor={`yes`}>
        <span>Yes</span>
      </label>
      <input
        type="radio"
        name={`parking`}
        value="no"
        id={`no`}
        checked={this.state.formControls[`parking`].value === 'no'}
        onChange={this.changeHandler}
        required
      />
      <label htmlFor={`no`}>
        <span>No</span>
      </label>
    </div>
  </>

  changeHandler = event => {
    const name = event.target.name
    const value = event.target.value

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: {
          ...this.state.formControls[name],
          value,
        },
      },
    })
  }

  increaseGuestForm = () => {
    if (this.state.guests < 4) {
      this.setState({ guests: this.state.guests + 1 })
    }
  }

  removeGuest = () => {
    if (this.state.guests > 1) {
      this.setState({ guests: this.state.guests - 1 })
    }
  }

  handleSubmit = e => {
    alert("The wedding already happened! Why are you RSVP'ing")
    // fetch('/', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    //   body: encode({ 'form-name': 'RSVP', ...this.state.formControls }),
    // })
    //   .then(() => alert('Success!'))
    //   .catch(error => alert(error))
    e.preventDefault()
  }

  render() {
    const guestIds = Array.apply(null, {
      length: this.state.guests,
    }).map(Number.call, Number)
    const maxGuestIds = Array.apply(null, {
      length: 4,
    }).map(Number.call, Number)
    return (
      <React.Fragment>
        <h2 className="major">RSVP</h2>
        {/* hidden form to allow netlify to find inputs. */}
        <form
          name="RSVP"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          style={{ display: 'None' }}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="RSVP" />
          {maxGuestIds.map(i => this.guestInfo(i, true))}
          {this.parkingField()}
        </form>
        {/* actual form*/}
        <form
          name="RSVP"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="RSVP" />
          {guestIds.map(i => this.guestInfo(i))}
          <ul className="actions">
            {this.state.guests < 4 ? (
              <li>
                <input
                  type="button"
                  value="Add Another Guest"
                  className="shadow"
                  onClick={this.increaseGuestForm}
                />
              </li>
            ) : null}
            {this.state.guests > 1 ? (
              <li>
                <input
                  type="button"
                  value="Remove Guest"
                  className="shadow"
                  onClick={this.removeGuest}
                />
              </li>
            ) : null}
          </ul>
          <br />
          {this.parkingField()}
          <ul className="actions">
            <li>
              <input type="submit" value="Send RSVP" className="special" onClick={this.handleSubmit} />
            </li>
          </ul>
        </form>
      </React.Fragment>
    )
  }
}
