import React, { Component } from 'react'

import ImageGallery from 'react-image-gallery'

import sailing from '../images/sailing.jpg'
import windy from '../images/windy.jpg'
import taiwan from '../images/taiwan.jpg'
import looking_off from '../images/looking_off.jpg'
import new_york from '../images/new_york.jpg'
import wookie from '../images/wookie.jpg'
import lucky_bun from '../images/lucky_bun.jpg'
import carry from '../images/carry.jpg'
import boop from '../images/boop.jpg'
import kiss from '../images/kiss.jpg'
import guitar from '../images/cutie-guitar.jpg'
import wet from '../images/wet.jpg'

const images = [
  {
    original: guitar,
  },
  {
    original: windy,
  },
  {
    original: taiwan,
  },
  {
    original: boop,
  },
  {
    original: looking_off,
  },
  {
    original: new_york,
  },
  {
    original: wookie,
  },
  {
    original: lucky_bun,
  },
  {
    original: carry,
  },
  {
    original: wet,
  },
  {
    original: kiss,
  },
  {
    original: sailing,
  },
]
export default class About extends Component {
  render() {
    return (
      <React.Fragment>
        <h2 className="major">Our Story</h2>
        <span className="image main">
          <ImageGallery
            items={images}
            showFullscreenButton={false}
            autoPlay={false}
            slideDuration={1000}
            showThumbnails={false}
          />
        </span>

        <hr></hr>
        <p>
          Chris and Paige met playing laser tag at McMaster in 2009. Paige ran
          into a wall, it was pretty embarrassing, but Chris helped cheer her up
        </p>
        <p>
          Paige was impressed that Chris took a bus to go get her an iced cream
          cake for her birthday, and brought it back to residence.
        </p>
        <p>
          Even though Chris was “robe guy,” he still managed to win her over. He’s no longer robe guy, but still wears shorts all the time.
        </p>
        {/* <p>
          He’s no longer robe guy, but still wears shorts all the time. He has
          recently voiced that he would like a robe again, Paige keeps
          selectively forgetting and getting him other gifts instead. Oops!
        </p> */}
        {/* <p>
          Chris and Paige, AKA “CRISPY”, have lived in Toronto together since
          2014, going on all sorts of adventures! Ya ya, laugh at the couple
          name, but we are cute and you know it. 😊
        </p> */}
        <p>
          Together they’ve been to Taiwan, Hong Kong, Macau, The US, Mexico and Greece!
        </p>
        <p>
          There are a lot of details in between.. but in May 2019 Chris proposed
          while they were on a hike together!
        </p>
        {/* <p>
          Chris will never live down the fact that there was a photographer
          friend on said hike that he did not let in on the details. Said friend
          was Jeff Leon, and he was half way to Tijuana (~10 min up the trail)
          when the proposal happened so no pictures exist of this exact moment
          (though some great ones were taken soon after!!)
        </p> */}

        {/* <p>
          Paige will never live down the fact that she didn’t know their car’s
          gas cap over is PUSH to open. She would just pry at it with her long
          and strong fingernails to open it while thinking how the heck does
          Chris do this? shrug. Chris found this out around the same time as the
          proposal, and as he watched her in the rearview struggling he silently
          wondered “Who am I marrying ?”
        </p> */}
        <p>May 23, 2020 Chris and Paige are getting married...</p>
        <p>Sometimes things don't go according to plan though, now the date is May 27, 2023!</p>
      </React.Fragment>
    )
  }
}
